import React from "react";
import { useLocation, useNavigate  } from 'react-router-dom';

import '../SCSS/Footer.scss'
import AnorLogo from '../Images/anor-technologies-logo.png'

const Footer = () => {
    const location = useLocation()
    const navigate = useNavigate()


    return (
        <div className="footer">
        <svg className='footer-wave' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill={['/industries'].includes(location.pathname)?("#1A1633"):("#FFFFFF")} fill-opacity="1" d="M0,224L60,229.3C120,235,240,245,360,261.3C480,277,600,299,720,293.3C840,288,960,256,1080,245.3C1200,235,1320,245,1380,250.7L1440,256L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"></path>
        </svg>
            <div className="footer-content">
                <div className="column">
                    <img src={AnorLogo} alt="" className="company-logo" />
                    <div className="subtitle">
                        Reflect the Universe Now with Senstrio
                    </div>
                </div>
                <div className="column">
                    <h4>SERVICES</h4>
                    <ul className="footer-column-list">
                        <li>Senstrio</li>
                        <li onClick={()=> {
                            navigate('/productsandservices')
                        }}>Subscription Plan</li>
                    </ul>                
                </div>
                <div className="column">
                    <h4>INDUSTRIES</h4>
                    <ul className="footer-column-list">
                        <li onClick={()=> {
                            navigate('/industries')
                        }}>Security</li>
                        <li onClick={()=> {
                            navigate('/industries')
                        }}>Manufacturing</li>
                        <li onClick={()=> {
                            navigate('/industries')
                        }}>Food Safety</li>
                    </ul>
                </div>
                <div className="column">
                    <h4>NEWS & MILESTONES</h4>
                    <ul className="footer-column-list">
                        <li onClick={()=> {
                            navigate('/aboutus')
                        }}>Updates</li>
                    </ul>
                </div>
                <div className="column">
                    <h4>CONTACT US</h4>
                    <ul className="footer-column-list">
                        <li onClick={()=> {
                            navigate('/contactus')
                        }}>Enquiries</li>
                        <li onClick={()=> {
                            navigate('/contactus')
                        }}>Location</li>
                    </ul>
                </div>
            </div>
            <div className="copyright">
                © Copyright ANOR Technologies Inc.
            </div>
        </div>
    )
}

export default Footer