import React from "react";
import { useInView } from "react-intersection-observer";

import "../SCSS/AboutUsPage.scss";
import missionImage from "../Images/about-us-page/mission-ambition.jpg";
import cultureImage from "../Images/about-us-page/meeting-hands.png";
import ceo from "../Images/about-us-page/ceo.png";
import quoteUp from "../Images/about-us-page/double-quote-up.png";
import quoteDown from "../Images/about-us-page/double-quote-down.png";
import blogPosts from "../blog-info";
import hand from "../Images/about-us-page/hand.png";
import stakeholderKevin from "../Images/about-us-page/kevin_cropped.jpg";
import stakeholderZhaoHao from "../Images/about-us-page/zhaohao_cropped.jpg";
import stakeholderPalma from "../Images/about-us-page/palma_cropped.jpg";
import window1 from "../Images/about-us-page/window1.jpg";
import window2 from "../Images/about-us-page/window2.jpg";
import window3 from "../Images/about-us-page/window3.jpg";
import window4 from "../Images/about-us-page/window4.jpg";

import { NavLink } from "react-router-dom";

const AboutUsPage = () => {
  const { ref: refMission, inView: inViewMission } = useInView({
    triggerOnce: true,
  });

  const { ref: refCulture, inView: inViewCulture } = useInView({
    triggerOnce: true,
  });

  const { ref: refQuote, inView: inViewQuote } = useInView({
    triggerOnce: true,
  });

  const { ref: refNews, inView: inViewNews } = useInView({
    triggerOnce: true,
  });

  const { ref: refShareholders, inView: inViewShareholders } = useInView({
    triggerOnce: true,
  });

  return (
    <div className="main about-page">
      <section className="hero-section">
        <div className="hero-content">
          <h1>About Us</h1>
          <p className="description">
            A Spectroscopy as a Service company looking to improve business
            procedures around the world
          </p>
          <div className="contact-box">
            <button className="contact-us-button">Contact us now </button>
          </div>
        </div>
        <div className="waving-hand">
          Hey there, meet the team!
          <img src={hand} alt="" className="hand" />
        </div>
        <svg
          className="wave"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1440 320"
        >
          <path
            fill="#ffffff"
            fill-opacity="1"
            d="M0,96L48,112C96,128,192,160,288,160C384,160,480,128,576,133.3C672,139,768,181,864,208C960,235,1056,245,1152,234.7C1248,224,1344,192,1392,176L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"
          ></path>
        </svg>
      </section>
      <section
        className={
          inViewMission ? "mission-section animate-mission" : "mission-section"
        }
        ref={refMission}
      >
        <div className="image-moving">
          <div className="window-column">
            <div className="window" id="window1">
              <div className="image-wrapper">
                <img src={window1} alt="" />
              </div>
            </div>
            <div className="window" id="window2">
              <div className="image-wrapper">
                <img src={window3} alt="" />
              </div>
            </div>
          </div>
          <div className="window-column" id="second-column">
            <div className="window" id="window3">
              <div className="image-wrapper">
                <img src={window2} alt="" />
              </div>
            </div>
            <div className="window" id="window4">
              <div className="image-wrapper">
                <img src={window4} alt="" />
              </div>
            </div>
          </div>
        </div>
        <div className="content">
          <h1>
            Our <span>Mission</span> and <span>Ambition</span>
          </h1>
          <p>
            At ANOR Technologies, we are dedicated to providing state-of-the-art
            spectroscopic devices and web platform solutions that unlock new
            realms of scientific understanding and enable businesses to thrive.
            Our mission is to facilitate transformative discoveries, improve
            product quality, and enhance operational efficiency for our valued
            partners. <br />
            <br />
            we are driven by a passion for excellence and a deep understanding
            of the impact spectroscopy can have on industries ranging from
            healthcare and pharmaceuticals to environmental monitoring and
            materials science. We are dedicated to delivering solutions that not
            only meet but exceed the expectations of our clients, empowering
            them to make informed decisions and achieve remarkable results.
          </p>
        </div>
      </section>
      <section
        className={
          inViewCulture ? "culture-section animate-culture" : "culture-section"
        }
        ref={refCulture}
      >
        <h1>
          Our Work <span>Culture</span>
        </h1>
        <p>
          At ANOR Technologies, our work culture is defined by collaboration,
          innovation, and a passion for excellence. We foster a dynamic and
          inclusive environment where every team member is valued and encouraged
          to contribute their unique perspectives and skills. We believe in the
          power of collaboration and teamwork, leveraging diverse talents to
          drive impactful solutions
        </p>
        <img src={cultureImage} alt="" />
      </section>
      <section
        className={
          inViewQuote ? "quote-section animate-quote" : "quote-section"
        }
        ref={refQuote}
      >
        <img src={ceo} alt="" className="ceo-image" />
        <div className="quote">
          <img src={quoteUp} alt="" className="quote-up quote-image" />
          <h2>
            <span className="typewriter" style={{ "--n": 100 }}>
              Our commitment to <span>innovation</span> and{" "}
              <span>excellence</span> fuels our passion for pushing the{" "}
              <span>limits</span> of what spectroscopy can achieve.
            </span>
          </h2>
          <img src={quoteDown} alt="" className="quote-down quote-image" />
        </div>
      </section>
      <section
        className={
          inViewShareholders
            ? "shareholders animate-shareholders"
            : "shareholders"
        }
        ref={refShareholders}
      >
        <h1>Meet Our Directors</h1>
        <p></p>
        <div className="card-wrapper" ref={refNews}>
          <div className="card-container">
            <div className="card">
              <img
                className="stake-holder-image"
                src={stakeholderKevin}
                alt="Stakeholder 2"
              />
              <div className="fields">
                <h3 class="stakeholder-name">Kevin Zhang</h3>
                <p className="stakeholder-field">Chief Executive Officer</p>
                <p className="stakeholder-field education">PhD of NUS</p>
              </div>
            </div>
            <div className="card">
              <img
                className="stake-holder-image"
                src={stakeholderPalma}
                alt="Stakeholder 2"
              />
              <div className="fields">
                <h3 class="stakeholder-name">Palma</h3>
                <p className="stakeholder-field">
                  Business Development Director
                </p>
                <p className="stakeholder-field education">EMBA of NUS</p>
              </div>
            </div>
            <div className="card">
              <img
                className="stake-holder-image"
                src={stakeholderZhaoHao}
                alt="Stakeholder 1"
              />
              <div className="fields">
                <h3 class="stakeholder-name">Zhao Hao</h3>
                <p className="stakeholder-field">Marketing Director</p>
                <p className="stakeholder-field education">EMBA of NUS</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className={inViewNews ? "news animate-news" : "news"}>
        <h1>News and Milestones</h1>
        <p>Get an insight on what we have done and achieved so far.</p>
        <div className="card-wrapper" ref={refNews}>
          <div className="card-container">
            {Object.keys(blogPosts).map((event, index) => {
              return (
                <NavLink to="/blog">
                  <div
                    className="card"
                    id={"card-" + index}
                    onClick={() => sessionStorage.setItem("blog-event", event)}
                  >
                    <div className="overlay"></div>
                    <img src={blogPosts[event].thumbnailImage} alt="" />
                    <h4 className="title">{blogPosts[event].thumbnailTitle}</h4>
                  </div>
                </NavLink>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default AboutUsPage;
