import international_workshop_2024 from './Images/blog/international-workshop.jpg'
import symposium_1_2024 from './Images/blog/budget-symposium-1.jpg'
import symposium_2_2024 from './Images/blog/budget-symposium-2.jpg'
import airshow_1_2024 from './Images/blog/airshow-1.jpg'
import airshow_2_2024 from './Images/blog/airshow-2.jpg'
import slingshot_1_2023 from './Images/blog/slingshot-2023-1.jpg'
import slingshot_2_2023 from './Images/blog/slingshot-2023-2.jpg'
import immrw_1_2023 from './Images/blog/irmmw-thz-2023-1.jpg'
import immrw_2_2023 from './Images/blog/irmmw-thz-2023-2.jpg'
import spearhead_1_2023 from './Images/blog/st-engineering-2023-1.jpg'
import ntu_innovation_2023 from './Images/blog/ntu-innovation-2023.jpg'

import forensics2022 from './Images/blog/forensics-blog.jpg'
import tupEagles from './Images/blog/tup-eagles-blog.jpg'
import switch2022 from './Images/blog/switch-blog.jpg'
import milipol2022 from './Images/blog/2022-forensics-blog.jpg'

import writerkz from './Images/blog/writerKZ.png'
import writerly from './Images/blog/writerLY.png'



const blogPosts = {
    '11thinternationalworkshop': {
        'thumbnailTitle': 'International Terahertz Workshop',
        'thumbnailImage': international_workshop_2024,
        'createdDate':"15 March 2024",
        'writerName':"Kevin Zhang",
        'writerImage':writerkz,
        "writerRole":"Chief Executive Officer at ANOR Technologies",
        'title': 'ANOR Technologies Pte Ltd Shines at the 11th International Workshop on Terahertz Technology and Applications',
        'headlineImage': international_workshop_2024,
        'content': <p>
            ANOR Technologies Pte Ltd had the honor of participating in the 11th International Workshop on Terahertz Technology and Applications (#TeraTec2024), 
            a momentous event facilitated by Fraunhofer ITWM. <br/><br/>
            During this influential gathering, ANOR Technologies proudly showcased our latest advancements in industrial applications, 
            driven by the integration of Terahertz Sensing and Artificial Intelligence of Things (AIoT) Technologies. 
            Our presentations sparked enriching discussions and engagements within the Terahertz community, 
            fostering promising collaborations geared towards harnessing Terahertz technology to address industrial complexities. <br/><br/> 
            Of particular note, we are excited about the transformative potential of AI technology in augmenting Terahertz sensing capabilities, 
            heralding a new era of unprecedented possibilities. This synergy between cutting-edge technologies signifies a significant stride towards 
            innovation and efficiency in industrial sectors. <br/><br/>
            We extend our heartfelt gratitude to Fraunhofer ITWM for orchestrating this remarkable event and look forward to furthering our collaborations 
            and contributing to the advancement of Terahertz technology and its applications in the industrial landscape. Stay tuned as we continue to pioneer 
            groundbreaking solutions at the intersection of Terahertz sensing and AI technologies. <br/><br/>
        </p>
    },

    'budgetsymposium2024': {
        'thumbnailTitle': 'Budget Symposium 2024',
        'thumbnailImage': symposium_1_2024,
        'createdDate':"8 March 2024",
        'writerName':"Kevin Zhang",
        'writerImage':writerkz,
        "writerRole":"Chief Executive Officer at ANOR Technologies",
        'title': 'Represented at Singapore Budget Symposium 2024',
        'headlineImage': symposium_2_2024,
        'content': <p>
            We are pleased to be invited to the Singapore Budget Symposium 2024. Our Business Development Director, Ms. Kathyrina Palma, 
            has represented our company at this esteemed event. <br/><br/>
            This year's Budget introduces meaningful measures aimed at supporting businesses through current challenges, 
            such as tax rebates and enhanced Enterprise Financing Scheme. <br/><br/>
            As a company deeply invested in technology and innovation, it is encouraging to see the government's commitment to invest more in R&D through RIE2025, 
            with a particular focus on emerging technologies such as AI for industrial application. 
            The Singapore Budget 2024 reflects a thoughtful approach to strengthening the economy and supporting enterprises like ours. <br/><br/>

        </p>
    },

    'airshow2024': {
        'thumbnailTitle': 'Singapore Airshow 2024',
        'thumbnailImage': airshow_1_2024,
        'createdDate':"23 February 2024",
        'writerName':"Kevin Zhang",
        'writerImage':writerkz,
        "writerRole":"Chief Executive Officer at ANOR Technologies",
        'title': 'Anor Makes a Stellar Debut at Singapore Airshow 2024',
        'headlineImage': airshow_2_2024,
        'content': <p>
            We are proud to announce that Anor has been extended the honor of participation at the esteemed Singapore Airshow 2024.
            As a burgeoning player in the aerospace industry, we are dedicated to revolutionizing quality control processes in aircraft manufacturing, 
            particularly focusing on Maintenance, Repair, and Overhaul (MRO) services.<br /><br />
            The Singapore Airshow 2024 provided a platform for us to not only introduce ourselves to the industry but also to forge valuable connections 
            and showcase our innovative solutions in Aerospace Non-Destructive Testing (NDT).
            It was an exhilarating experience to interact with numerous industry peers, make new acquaintances, and unveil our cutting-edge advancements. <br /><br />
            Our team is eagerly looking forward to collaborating with partners to cater to the evolving needs of the aviation sector and 
            contribute to the sustained growth of our company. The event served as an excellent opportunity to engage with potential clients and collaborators, 
            demonstrating our commitment to delivering intelligent products and solutions.<br /><br />
            The atmosphere at the Singapore Airshow was nothing short of electric, with visionaries and industry frontrunners converging from across the globe 
            to exchange insights and establish groundbreaking partnerships. We are thrilled to have been a part of this dynamic event, 
            where we explored new horizons in aviation and laid the groundwork for exciting future endeavors.<br /><br />
            Stay tuned as we continue to innovate and make strides in revolutionizing the aerospace industry with our pioneering technologies and unwavering dedication to excellence. 
            Anor looks forward to the journey ahead, fueled by the momentum gained from our debut at the Singapore Airshow 2024. 
        </p>
    },

    'slingshot2023': {
        'thumbnailTitle': 'Slingshot 2023',
        'thumbnailImage': slingshot_1_2023,
        'createdDate':"1 November 2023",
        'writerName':"Kevin Zhang",
        'writerImage':writerkz,
        "writerRole":"Chief Executive Officer at ANOR Technologies",
        'title': 'ANOR Technologies Pte Ltd Celebrates Success at SLINGSHOT 2023',
        'headlineImage': slingshot_2_2023,
        'content': <p>
            ANOR Technologies Pte Ltd is proud to extend heartfelt congratulations to all the outstanding winners of SLINGSHOT 2023! 🌟🏆 
            The journey through this prestigious competition has been nothing short of extraordinary, 
            and we are truly honored to have had the opportunity to connect with such remarkable individuals. <br/><br/>
            The unwavering passion and boundless energy displayed by the visionary entrepreneurs we encountered are truly infectious and inspiring. 
            Being recognized as one of the TOP 50 global startups is an immense privilege, 
            one that fuels our determination to push the boundaries of innovation even further. <br/><br/>
            At ANOR Technologies, we are committed to refining our sensing solutions to achieve even greater market recognition and impact. 
            Together with our fellow innovators, we are shaping the future and making a lasting impact on industries worldwide. 🚀💼<br/><br/>
            As we celebrate our success at SLINGSHOT 2023, let us continue to inspire and drive positive change in the world. 
            Stay tuned for more updates as we embark on this exciting journey of innovation and growth. <br/><br/>

        </p>
    },

    '48th-immrw-thz': {
        'thumbnailTitle': '48th IRMMW-THz Conference',
        'thumbnailImage': immrw_1_2023,
        'createdDate':"23 September 2023",
        'writerName':"Kevin Zhang",
        'writerImage':writerkz,
        "writerRole":"Chief Executive Officer at ANOR Technologies",
        'title': 'Anor Technologies Wraps Up Successful Participation at 48th IRMMW-THz Conference',
        'headlineImage': immrw_2_2023,
        'content': <p>
            Anor Technologies is delighted to announce the successful conclusion of our engagement at the 48th Infrared Millimeter and Terahertz Waves (IRMMW-THz) Conference.
            It was an honor to be granted the opportunity by the esteemed committee to present our pioneering study, 
            which merges Artificial Intelligence with Terahertz Spectroscopy for narcotics identification. <br/><br/>
            Our proposed solution showcases the potential to revolutionize the detection and identification of concealed narcotics, 
            thereby bolstering the efforts of the public safety sector towards achieving a drug-free society. 
            The collaboration and exchange of insights at the conference have propelled us further towards this shared mission of creating a safer tomorrow. <br/><br/>
            Throughout the week-long event, held at Centre Mont-Royal in Montreal, Canada, we were privileged to engage with industry leaders, innovators, 
            and passionate professionals from around the globe. We extend our heartfelt gratitude to everyone who visited our booth, 
            contributing to enriching discussions and insights into the latest Terahertz research and inventions. <br/><br/>
            As we reflect on our participation in the 48th IRMMW-THz Conference, 
            we remain committed to pushing the boundaries of innovation and collaboration in the realm of Terahertz technology. <br/><br/>

        </p>
    },

    'spearheads-technological-revolution': {
        'thumbnailTitle': 'ST, A*STAR Tech Revolution',
        'thumbnailImage': spearhead_1_2023,
        'createdDate':"5 May 2023",
        'writerName':"Kevin Zhang",
        'writerImage':writerkz,
        "writerRole":"Chief Executive Officer at ANOR Technologies",
        'title': 'ANOR Technologies Pte Ltd Spearheads Technological Revolution at ST Engineering and A*STAR Event',
        'headlineImage': spearhead_1_2023,
        'content': <p>
            Under the theme "Revolutionising the Future of Technology," ANOR Technologies Pte Ltd recently took center stage at an engaging event organized 
            by ST Engineering and supported by A*STAR - Agency for Science, Technology and Research. <br/><br/>
            As a trusted solution provider, ANOR was eager to unveil our latest advancements in Terahertz (THz) technology during the event. 
            Attendees were treated to a live demonstration and exclusive laboratory tour, showcasing the capabilities of our cutting-edge products. 
            Additionally, we introduced "Senstrio," our latest software service, which garnered widespread acclaim and positive feedback from the audience. <br/><br/>
            The event proved to be a resounding success, with attendees expressing keen interest and curiosity about our innovative offerings. 
            It served as a prime opportunity for ANOR to highlight our expertise and capabilities in the ever-evolving landscape of technology. <br/><br/>
            We extend our heartfelt gratitude to ST Engineering and A*STAR for their invaluable support and collaboration throughout the event. 
            This partnership has been instrumental in driving our mission to push the boundaries of technological innovation. <br/><br/>
            To all who attended, we express our sincerest thanks for your participation. 
            We are excited about the possibilities that lie ahead and look forward to exploring new avenues of collaboration with each of you. 
            Together, let's continue to shape the future of technology! <br/><br/> 

        </p>
    },

    'ntu-innovation': {
        'thumbnailTitle': 'NTU Innovation and Entrepreneurship',
        'thumbnailImage': ntu_innovation_2023,
        'createdDate':"17 March 2023",
        'writerName':"Kevin Zhang",
        'writerImage':writerkz,
        "writerRole":"Chief Executive Officer at ANOR Technologies",
        'title': 'ANOR Technologies Pte Ltd Attends NTU Innovation and Entrepreneurship Launch Ceremony',
        'headlineImage': ntu_innovation_2023,
        'content': <p>
            ANOR Technologies Pte Ltd is thrilled to announce our participation in the launch ceremony of NTU Innovation and Entrepreneurship (NTU I&E). 
            It was a pleasure to engage with numerous like-minded and talented entrepreneurs spanning various industries at this esteemed event. <br/><br/>
            We extend our sincere gratitude to Ms. Low Yen Ling, Minister of State, Ministry of Trade and Industry (Singapore),  
            for gracing us with her presence during the ceremony. Additionally, we would like to express special thanks to Mr. Boon Chow LIM, 
            whose exceptional mentorship has been instrumental in providing us with the support and resources necessary for success in the startup ecosystem. <br/><br/>
            The launch ceremony left us with a strong impression of Nanyang Technological University Singapore's commitment to fostering cutting-edge startups 
            with the potential to make a global impact. <br/><br/>
            We commend all the organizers for their outstanding efforts in orchestrating such an amazing launch event. 
            Furthermore, we are grateful to everyone we had the pleasure of meeting for the enriching conversations and valuable connections made. <br/><br/>
            Looking ahead, ANOR Technologies eagerly anticipates staying in touch with the inspiring individuals we met at Nanyang Technological University Singapore. 
            We are committed to continuing our collaboration, exploring new ideas, and seizing opportunities together. 🎉<br/><br/>

        </p>
    },

    'forensics2022': {
        'thumbnailTitle': 'NAFSLE 2022',
        'thumbnailImage': forensics2022,
        'createdDate':"3 November 2022",
        'writerName':"Liang Ying",
        'writerImage':writerly,
        "writerRole":"Business Manager at ANOR Technologies",
        'title': 'National Forensic Science Symposium for Law Enforcement 2022',
        'headlineImage': forensics2022,
        'content': <p>
            Another exciting post to wrap up this hectic week for ANOR! Our pleasure to be 
            invited for the National Science Symposium for Law Enforcement (NAFSLE) 
            from 26th to 28th October, which organized by Royal Malaysia Police.<br /><br />  
            The agenda of this symposium is to address the challenge in forensic science 
            in investigating and to promote the evolution and innovation of the forensic science. 
            We are given the opportunity to exhibit range of spectroscopy products 
            and services that could help to meet the challenge of narcotics detection and identification.
            <br /><br /> Many of the visitors were intrigued by our Senstrio Cloud services, 
            they’ve never thought that narcotics tracing can be so easy, seamless and efficient. 
            <br /><br />One of the senior police officer mentioned that Senstrio Cloud services could be a 
            great asset in combating the rising threat of NPS. <br /><br /> He said, 
            “The number of NPS type has been increasing rapidly within the past few years. 
            In order to combat this, we need something that can keep on learning about the 
            new drug available in the market and constantly update its database. Not just being that, 
            the digital connected service provided by Senstrio allows data sharing among the organization 
            which helps us to identify new drug faster than before.”
        </p>
    },

    'tupaward': {
        'thumbnailTitle': 'T-Up Award',
        'thumbnailImage': tupEagles,
        'createdDate':"3 November 2022",
        'writerName':"Liang Ying",
        'writerImage':writerly,
        "writerRole":"Business Manager at ANOR Technologies",
        'title': 'T-Up Excellence Award',
        'headlineImage': tupEagles,
        'content': <p>
            Congrats to Phua Wei Ji for receiving the T-Up Eagles Award today! <br /><br />
            Through our T-Up with A*STAR – Agency for Science, Technology and Research, and 
            Singapore Institute of Technology‘s Integrated Work Study Programme (IWSP) Wei Ji 
            and his T-Up mentor Dr Yee-Fun Lim from Institute of Materials Research and Engineering 
            (IMRE) developed the ANOR Data Analyzer (ADA) application hosted on ANOR’s spectrometer
            device. It allows the quick collection and identification of drug components and chemicals 
            through its spectral fingerprints. Above all outcomes, we have successfully launched our 
            Senstrio product, which is a Spectroscopy as a Service platform.<br /><br /> 
            In addition, we are truly honoured that Dr Tan See Leng, Minister for Manpower and 
            Second Minister for Trade and Industry specially mentioned Phua Wei Ji from Enterprise Singapore, 
            and Singapore Institute of Technology for their unwavering support. ANOR Technologies will continue to 
            collaborate with our partners and advocate for nurturing young talents for the deep tech start-up ecosystem.
        </p>
    },

    'switch2022': {
        'thumbnailTitle': 'SWITCH 2022',
        'thumbnailImage': switch2022,
        'createdDate':"1 November 2022",
        'writerName':"Liang Ying",
        'writerImage':writerly,
        "writerRole":"Business Manager at ANOR Technologies",
        'title': 'SWITCH 2022',
        'headlineImage': switch2022,
        'content': <p>
            The first two days of Singapore Week of Innovation and Technology (SWITCH) 2022 was a blast! 
            Team ANOR got the chance to make new connections with many visitors, both locally and internationally! 
            It was a great avenue for ANOR Technologies to increase exposure through networking. We introduced Senstrio Cloud 
            as part of ANOR’s spectroscopy services. Senstrio Cloud integrate Artificial Intelligence (AI) and 
            spectroscopy technology to provide a suite of cloud-based solutions, enabling on-site sample testing for easy analysis. <br /><br />
            ANOR Technologies takes terahertz spectroscopy to the next level by integrating hardware and software to provide 
            a seamless connection, letting users to truly sense beyond the 
        </p>
    },

    'milipol2022': {
        'thumbnailTitle': 'Milipol 2022',
        'thumbnailImage': milipol2022,
        'createdDate':"1 November 2022",
        'writerName':"Liang Ying",
        'writerImage':writerly,
        "writerRole":"Business Manager at ANOR Technologies",
        'title': 'Milipol 2022',
        'headlineImage': milipol2022,
        'content': <p>
            Team ANOR’s first Milipol event was a blast! We are grateful for the opportunity to demonstrate the capabilities of our Terahertz spectroscopy 
            technology for drug and explosive detection and to receive such encouraging responses. <br /><br />
            This is a milestone to strengthen our presence in Asia Pacific from the engagement we had with visitors and d
            elegates belonging to many fields of expertise. <br /><br />To advance ANOR into the deep-tech sector, we’ve taken in a lot of favorable feedback 
            and focus on our proprietary technology. <br /><br /> Anor is committed to be the cutting-edge provider of Terahertz sensing solutions to combat 
            security threats to public safety.
        </p>
    }
}

export default blogPosts