import React from "react";
import { useInView } from "react-intersection-observer";

import '../SCSS/ProductsAndServicesPage.scss'
import serviceImage from '../Images/products-page/software-background.jpg'
import senstrioAI from '../Images/products-page/senstrio-ai.jpg'
import senstrioSwift from '../Images/products-page/senstrio-swift.jpg'

import xstds01 from '../Images/products-page/device-images/XS-TDS-01.jpg'
import raman from '../Images/products-page/device-images/Raman.jpg'
import menlo from '../Images/products-page/device-images/Menlo.jpg'
import fibrecoupleddevice from '../Images/products-page/device-images/Fibre Coupled Device.jpg'
import thicknessmeasurement from '../Images/products-page/device-images/Paint Thickness.jpg'
import goyaLab from '../Images/products-page/device-images/GOYA.jpg'
import crownBasic from '../Images/products-page/crown-basic.png'
import crownPro from '../Images/products-page/crown-pro.png'
import crownEnterprise from '../Images/products-page/crown-enterprise.png'

const ProductsAndServicesPage = () => {

    const scrollToSubscription = () => {
        window.scrollTo({
            top: 5400,
            behavior: 'smooth',
        });
    }

    const scrollToLearn = () => {
        window.scrollTo({
            top: 750,
            behavior: 'smooth',
        });
    }

    const { ref:refSoftware, inView:inViewSoftware } = useInView({
        triggerOnce:true
    });

    const { ref:refBlock1, inView:inViewBlock1} = useInView({
        triggerOnce:true
    });

    const { ref:refBlock2, inView:inViewBlock2} = useInView({
        triggerOnce:true
    });

    const { ref:refHardware, inView:inViewHardware} = useInView({
        triggerOnce:true
    });


    const { ref:refSubscribe, inView:inViewSubscribe} = useInView({
        triggerOnce:true
    });



    return(
        <div className="main service-page">
            <section className="hero-section">
                <div className="hero-content">
                    <h1>Products and Services</h1>
                    <p className="description">
                        Checkout our list of products and services that fit your business plans and needs
                    </p>
                    <div className="learn-more-box">
                        <button className="subscription-button" onClick={()=> scrollToSubscription()}>Checkout Subscriptions</button>
                        <button className="learn-more-button secondary-button" onClick={()=> scrollToLearn()}>Learn More</button>
                    </div>
                </div>
                <svg  className='wave' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ffffff" fill-opacity="1" d="M0,96L48,112C96,128,192,160,288,160C384,160,480,128,576,133.3C672,139,768,181,864,208C960,235,1056,245,1152,234.7C1248,224,1344,192,1392,176L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
            </section>
            <section className={inViewSoftware?"services-section animate-services":"services-section"} ref={refSoftware}>
                <h1>Senstrio Software</h1>
                <p className="description">
                    Senstrio Software simplifies spectroscopy data processing, ML, and device management. Designed for spectroscopy hardware users, this user-friendly tool leverages advanced algorithms for streamlined data processing. With cross-platform support, intuitive data management, and seamless integration between SenstrioAI and SenstrioSwift, users can effortlessly interpret results, create ML models, and gain valuable insights. Revolutionize your workflow and enhance data-driven capabilities with Senstrio Software across industries like drug detection, manufacturing, plastic sorting, and food sciences.
                </p>
                <div className="service-image-wrapper">
                    <img src={serviceImage} alt="" />
                </div>

            </section>
            <section className="why-us-section">
                <div className={inViewBlock1?"block animate-1":"block"} id='block1' ref={refBlock1}>
                    <div className="content">
                        <h1>Senstrio <span>AI</span></h1>
                        <p>
                            SenstrioAI simplifies spectroscopy data processing and ML for material identification. Designed for spectroscopy hardware users, 
                            this user-friendly research and development tool leverages advanced algorithms and comprehensive data processing. With cross-platform 
                            support and intuitive data management, it streamlines the processing of diverse data collections. Users can effortlessly interpret results 
                            and create ML models without the need for programming skills. 
                            <br /><br />
                            SenstrioAI empowers users to unlock valuable insights and drive innovation with ease, revolutionizing their workflow by combining data processing 
                            and ML in a seamless platform.
                        </p>
                    </div>
                    <div className="image-wrapper">
                        <img src={senstrioAI} alt="" />
                    </div>
                </div>
                <div className={inViewBlock2?"block animate-2":"block"} id='block2' ref={refBlock2}>
                    <div className="image-wrapper">
                        <img src={senstrioSwift} alt="" />
                    </div>

                    <div className="content">
                        <h1>Senstrio <span>Swift</span></h1>
                        <p>
                            SenstrioSwift is a user-friendly centralized management system that provides seamless 
                            control and analysis of your devices. Gain valuable insights, track data, and solve
                            business problems with ease. Accessible through a Web Portal and Mobile Application, 
                            SenstrioSwift empowers you to manage your devices anytime, anywhere. 

                            Seamlessly integrate data collected from SenstrioSwift into SenstrioAI for training 
                            ML models and making data-driven decisions. With applications in various industries 
                            like drug detection, manufacturing, plastic sorting, and food sciences, SenstrioSwift 
                            streamlines device management and enhances your business's data-driven capabilities.
                        </p>
                    </div>
                </div>
            </section>
            <section className={inViewHardware?"devices-section animate-device" :"devices-section" } ref={refHardware}>
                <h1>Senstrio <span>Hardware</span></h1>
                <p className="description">
                    ANOR Technologies offers a comprehensive range of hardware solutions for electromagnetic (EM) wave analysis, including specialized Terahertz devices. Our state-of-the-art hardware is designed to enable precise and accurate measurements of EM waves, providing valuable insights into various applications. With Senstrio, you can harness the power of EM waves and Terahertz technology to unlock new possibilities in research, development, and innovation.
                </p>
                <div className="device-box-wrapper">
                    {/* <img src={serviceBackground} className="service-background"/> */}
                    <div className="device-box">                    
                        <div className="device-card">
                            <h4 className="purpose">CHEMICAL IDENTIFICATION</h4>
                            <h2 className="name">Portable Scanner</h2>
                            <p className="description">Portable Terahertz time domain spectroscopy device </p>
                            <img src={xstds01} alt="" className="device" />
                        </div>
                        <div className="device-card" id='raman'>
                            <h4 className="purpose">CHEMICAL IDENTIFICATION</h4>
                            <h2 className="name">RAMAN</h2>
                            <p className="description">Raman, Infrared Spectroscopy device</p>
                            <img src={raman} alt="" className="device" />
                        </div>
                        <div className="device-card" id='fibre'>
                            <h4 className="purpose">CHEMICAL IDENTIFICATION</h4>
                            <h2 className="name">Fibre-Coupled Terahertz Device</h2>
                            <p className="description">Terahertz time domain spectroscopy device </p>
                            <img src={fibrecoupleddevice} alt="" className="device" />
                        </div>
                        <div className="device-card">
                            <h4 className="purpose">CHEMICAL IDENTIFICATION</h4>
                            <h2 className="name">Near Infrared Device</h2>
                            <p className="description">Near Infrared portable spectrometer that fits in the palm of your hand</p>
                            <img src={goyaLab} alt="" className="device" />
                        </div>
                    </div>
                </div>
            </section>
            <section className={inViewSubscribe?"subscription-section animate-subscription":"subscription-section"} ref={refSubscribe}>
                <h1>How do I <span>Subscribe</span></h1>
                <p className="description">
                    In order to subscribe to our service, it requires a 3 step process. This allows us to understand you business necessities and support you better in your spectroscopy related endeavors. Checkout our deals below and see what fits your company best.               
                </p>
                <div className="subscription-block">
                    <div className="block-number">
                        <div>1</div>
                        <p>SELECT A SOFTWARE PLAN</p>
                    </div>
                    <div className="cards">
                        <div className="card">
                            <img src={crownBasic} alt="" />
                            <h3>Senstrio Basic</h3>
                            <p className="description">
                                Allow user to collect time-domain THz signal. Fast Fourier Transform (FFT) to convert time-domain THz signal to frequency domain, and for tracking data collection process.
                            </p>
                            <ul className="entitlements">
                                <li className="item">Senstrio Swift</li>
                                <li className="item">&le; 3 Users</li>
                                <li className="item">100 GB Cloud Storage</li>
                            </ul>
                        </div>
                        <div className="card">
                            <img src={crownPro} alt="" />
                            <h3>Senstrio Plus</h3>
                            <p className="description">
                                With SenstrioSwift, users can perform data labelling, training, and deploying Machine Learning models. Users are also able to construct a new spectra library for their applications. Project management digital workspaces are also available for laboratory research & development.                            </p>
                            <ul className="entitlements">
                                <li className="item">Senstrio Swift</li>
                                <li className="item">Senstrio AI</li>
                                <li className="item">&le; 10 Users</li>
                                <li className="item">1 TB Cloud Storage</li>
                                <li className="item">Mobile App Access</li>
                            </ul>
                        </div>
                        <div className="card">
                            <img src={crownEnterprise} alt="" />
                            <h3>Senstrio Enterprise</h3>
                            <p className="description">
                                Allow user to collect time-domain THz signal. Fast Fourier Transform (FFT) to convert time-domain THz signal to frequency domain, and for tracking data collection process.
                            </p>
                            <ul className="entitlements">
                                <li className="item">Senstrio Swift</li>
                                <li className="item">Senstrio AI</li>
                                <li className="item">Senstrio One</li>
                                <li className="item">&le; Unlimited Users</li>
                                <li className="item">Customise documents and features</li>
                                <li className="item">API & App Integration</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="subscription-block">
                    <div className="block-number">
                        <div>2</div>
                        <p>LEASING PLAN</p>
                    </div>
                    <div className="cards">
                        <div className="card">
                            <img src={crownBasic} alt="" />
                            <h3>On-Premise</h3>
                            <p className="description">
                                Please contact our sales person, we will provide you the best price based on your needs.                            
                            </p>
                        </div>
                        <div className="card">
                            <img src={crownPro} alt="" />
                            <h3>Off-Premise</h3>
                            <p className="description">
                                Please contact our sales person, we will provide you the best price based on your needs.        
                            </p>                    
                        </div>
                        <div className="card">
                            <img src={crownEnterprise} alt="" />
                            <h3>Project Based</h3>
                            <p className="description">
                                Please contact our sales person, we will provide you the best price based on your needs.                            
                            </p>
                        </div>
                    </div>
                </div>
                <div className="subscription-block">
                    <div className="block-number">
                        <div>3</div>
                        <p>ADD ON MODULES</p>
                    </div>
                    <div className="cards">
                        <div className="card">
                            <img src={crownBasic} alt="" />
                            <h3>Narcotics Analyzer Module (Basic)</h3>
                            <p className="description">
                                Auto-identification of narcotics
                            </p>
                            <p className="description">
                                Machine learning algorithm consists of up to 12 Narcotics spectra library
                            </p>
                        </div>
                        <div className="card">
                            <img src={crownPro} alt="" />
                            <h3>Explosives Analyzer Module (Basic)</h3>
                            <p className="description">
                                Auto-identification of explosives
                            </p>
                            <p className="description">
                                Machine learning algorithm consists up to 5 Explosives spectra library                            
                            </p>
                        </div>
                        <div className="card">
                            <img src={crownEnterprise} alt="" />
                            <h3>Customise Module</h3>
                            <p className="description">
                                Need to add more spectra libraries or features? Call for Pricing!                            
                            </p>
                        </div>
                    </div>
                </div>
                
            </section>
        </div>
    )
}

export default ProductsAndServicesPage