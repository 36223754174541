import React, { useEffect } from "react";

import '../SCSS/BlogPage.scss'
import writer from '../Images/blog/writerLY.png'
import forensicsBlog from '../Images/blog/forensics-blog.jpg'
import blogPosts from "../blog-info";

const Blog = () => {

    const key = sessionStorage.getItem('blog-event')

    return(
        <div className="main blog-page">
            
            <h1>{blogPosts[key].title}</h1>

            <div className="writer">
                <img src={blogPosts[key].writerImage} alt="" />
                <p className="name">{blogPosts[key].writerName}</p>
                <div className="divider"></div>
                <p className="date">{blogPosts[key].createdDate}</p>
            </div>
            <div className="main-image">
                <img src={blogPosts[key].headlineImage} alt="" />
            </div>
            <p className="main-passage">
                {blogPosts[key].content}
            </p>
            <div className="bottom-writer">
                <img src={blogPosts[key].writerImage} alt="" />
                <div className="content">
                    <h4>WRITTEN BY</h4>
                    <h3>{blogPosts[key].writerName}</h3>
                    <p>{blogPosts[key].writerRole}</p>
                </div>
            </div>
        </div>
    )
}

export default Blog