import React from "react";
import { Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import useDocumentTitle from "./Components/useDocumentTitle";

import HeroPage from "./Components/HeroPage";
import ContactUsPage from "./Components/ContactUs";
import ProductsAndServicesPage from "./Components/ProductsAndServices";
import NavigationBar from "./Components/NavigationBar";
import Footer from "./Components/Footer";
import AboutUsPage from "./Components/AboutUs";
import Blog from './Components/Blog';
import IndustriesPage from './Components/Industries'

function Home() {
  useDocumentTitle('Home Page')
  return <HeroPage />
}
function About() {
  useDocumentTitle('About Us')
  return <AboutUsPage />
}
function BlogArticle() {
  useDocumentTitle('Blog Page')
  return <Blog/>
}
function ContactUs() {
  useDocumentTitle('Contact Us')
  return <ContactUsPage />
}

function ProductsAndServices() {
  useDocumentTitle('Products and Services')
  return <ProductsAndServicesPage />
}

function Industries() {
  useDocumentTitle('Industries')
  return <IndustriesPage />
}


function App() {
  return (
    <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <link href='https://unpkg.com/boxicons@2.1.4/css/boxicons.min.css' rel='stylesheet' />
        </Helmet>
        <NavigationBar />
        <Routes>
          <Route path="/" element={<Home />}/>
          <Route path="/aboutus" element={<About />}/>
          <Route path="/blog" element={<BlogArticle />}/>
          <Route path="/contactus" element={<ContactUs />}/>
          <Route path="/productsandservices" element={<ProductsAndServices />}/>
          <Route path="/industries" element={<Industries />}/>
        </Routes>
        <Footer></Footer>
        
    </div>
  );
}

export default App;
