// Module Libraries
import React from "react";
import { useNavigate } from "react-router-dom";
import { useInView } from "react-intersection-observer";

// Images and Styles
import '../SCSS/HeroPage.scss'
import hardwareIcon from '../Images/hero-page/hardware-icon.png'
import senstrioAiIcon from '../Images/hero-page/senstrio-ai-icon.png'
import senstrioSwiftIcon from '../Images/hero-page/senstrio-swift-icon.png'
import serviceBackground from '../Images/hero-page/services-image.jpg'
import functionalityDiagram from '../Images/hero-page/functionality-diagram.jpg'
import iotImage from '../Images/hero-page/iot-image.png'
import handShakeImage from '../Images/hero-page/handshake-image.png'
import webservicImage from '../Images/hero-page/webservice-image.png'
import storageImage from '../Images/hero-page/storage-image.png'
import IsoWave from "../Images/hero-page/isometric-wave-svg";

const HeroPage = () => {

    const navigate = useNavigate()
    const { ref:refServices, inView:inViewServices } = useInView({
        triggerOnce:true
    });

    const { ref:refFunctionality, inView:inViewFunctionality } = useInView({
        triggerOnce:true
    });

    const { ref:refWhyUsBlock1, inView:inViewWhyUsBlock1 } = useInView({
        triggerOnce:true
    });

    const { ref:refWhyUsBlock2, inView:inViewWhyUsBlock2 } = useInView({
        triggerOnce:true
    });
    
    const { ref:refWhyUsBlock3, inView:inViewWhyUsBlock3 } = useInView({
        triggerOnce:true
    });
    
    const { ref:refWhyUsBlock4, inView:inViewWhyUsBlock4 } = useInView({
        triggerOnce:true
    });

    return (
        <div className="main hero-page">
            {/* Main Hero Section */}
            <section className="hero-section">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <div className="hero-content">
                    <h1 className="top-h1">Reflect the <span>Universe</span></h1>
                    <h1 className="bottom-h1">with Senstrio</h1>
                    <p className="description">
                        Harness the Power of Spectroscopy with our advanced EMW Devices 
                        and Web Platform Services. Transform your data into insights and 
                        improve your own projects.
                    </p>
                    <div className="contact-box">
                        <button className="contact-us-button" onClick={() => navigate('/contactus')}>
                            Contact us now 
                        </button>
                    </div>
                </div>
                <svg  className='wave' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320"><path fill="#ffffff" fill-opacity="1" d="M0,96L48,112C96,128,192,160,288,160C384,160,480,128,576,133.3C672,139,768,181,864,208C960,235,1056,245,1152,234.7C1248,224,1344,192,1392,176L1440,160L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path></svg>
            </section>

            {/* Services Section to display our 3 items SAI SSwift and EMSHardware */}
            <section className={inViewServices?'services-section animate-services': 'services-section'} ref={refServices}>
                <h1 className="top-h1">We have <span>much</span> to provide </h1>
                <h1 className="bottom-h1">for your company</h1>
                <p className="description">
                    Our company provides both advanced devices and machinery, which operates on the 
                    spectroscopic branch of physics. We also give users access to our web platform services, that allow for Data Analytics, Machine Learning Development, Data Management and Device Organisation
                </p>
                <div className="service-box-wrapper">
                    <img src={serviceBackground} className="service-background"/>
                    <div className="services-box">
                        <div className="service-card card-1">
                            <img src={senstrioAiIcon} alt="" className="logo" />
                            <div className="card-title">Senstrio AI</div>
                            <div className="card-info">
                                Senstrio AI is a research based web platform designed to allow users to 
                                conduct data management, 
                                analytics as well as no-code AI development. 
                            </div>
                        </div>
                        <div className="service-card card-2">
                            <img src={senstrioSwiftIcon} alt="" className="logo" />
                            <div className="card-title">Senstrio Swift</div>
                            <div className="card-info">
                                Senstrio Swift is a management based web service that allows users to organize and control hardware rented out from us.
                            </div>
                        </div>
                        <div className="service-card card-3">
                            <img src={hardwareIcon} alt="" className="logo" />
                            <div className="card-title">EMS Hardware</div>
                            <div className="card-info">
                            In our company, we provide advanced Electromagnetic hardware which you can rent out and use it for you own company’s purpose
                            </div>
                        </div>
                    </div>
                    <button className="learn-more-button" onClick={() => navigate('/productsandservices')}>Learn More</button>
                </div>

            </section>

            {/* Section to display functionality of our service */}
            <section className={inViewFunctionality?"functionality-section animate-functionality":"functionality-section"} ref={refFunctionality}>
                <div className="functionality-box">
                    <div className="image">
                        <img src={functionalityDiagram} alt="" className="image-1"/>
                    </div>
                </div>
            </section>

            {/* Section on 4 reasons why people should choose us */}
            <section className="why-us-section">
                <h1>Why Choose Us</h1>
                <div ref={refWhyUsBlock1} className={inViewWhyUsBlock1?"block animate-1":"block"} id='block1'>
                    <div className="content">
                        <h1><span>ASEAN 1st</span> Spectroscopy as a Service Platform</h1>
                        <p>
                            As the pioneer in our field, ANOR Technologies is your unrivaled 
                            destination for unlocking the true potential of spectroscopy and 
                            harnessing the immense power of our cutting-edge web platform services. 
                            With an unwavering commitment to innovation and excellence, 
                            we have established ourselves as the premier provider of these unique 
                            services in the ASEAN region.
                        </p>
                    </div>
                    <div className="image-wrapper">
                        <img src={webservicImage} alt="" />
                    </div>
                </div>
                <div ref={refWhyUsBlock2} className={inViewWhyUsBlock2?"block animate-2":"block"} id='block2'>
                    <div className="image-wrapper">
                        <img src={handShakeImage} alt="" />
                    </div>

                    <div className="content">
                        <h1>One-stop Service <span>Exclusively</span> for you</h1>
                        <p>
                            At ANOR Technologies, we recognize that every business is distinct and 
                            has its own set of requirements. That's why we go above and beyond to 
                            provide a curated package that precisely caters to your company's specific needs. 
                            With our comprehensive suite of spectroscopic devices and web platform services, 
                            we offer a transformative solution tailored exclusively for your business.
                        </p>
                    </div>
                </div>
                <div ref={refWhyUsBlock3} className={inViewWhyUsBlock3?"block animate-3":"block"} id='block3'>
                    <div className="content">
                        <h1><span>Scalability</span> and <span>Flexibility</span></h1>
                        <p>
                            Scalability lies at the heart of our service offerings. We have designed our 
                            solutions to seamlessly accommodate your evolving needs, whether you're a small 
                            startup or a large enterprise. As your business expands, our services can effortlessly 
                            scale up to meet the growing demands, ensuring that you have the necessary resources.
                        </p>
                    </div>
                    <div className="image-wrapper">
                        {/* <img src={storageImage} alt="" /> */}
                        <IsoWave />
                    </div>
                </div>
                <div ref={refWhyUsBlock4} className={inViewWhyUsBlock4?"block animate-4":"block"} id='block4'>
                    <div className="image-wrapper">
                        <img src={iotImage} alt="" />
                    </div>
                    <div className="content">
                        <h1><span>Enhancing</span> Business Performances</h1>
                        <p>
                            ANOR Technologies offers a comprehensive range of products and services meticulously 
                            crafted to unleash your business's true potential. With our cutting-edge solutions, 
                            you can elevate your capability, optimize resources, and achieve exceptional product quality.
                        </p>
                    </div>
                </div>
            </section>

            {/* Final Slogan */}
            <section className="slogan-section">
                <h1>Unlocking Limitless Insights</h1>
                <h3 className="subtitle">Empowering Your Journey with ANOR Technologies</h3>
                <div className="contact-box">
                    <button className="contact-us-button" onClick={() => navigate('/contactus')}>Contact us now </button>
                </div>
            </section>
            <section className="empty-space">

            </section>
        </div>
    )
}

export default HeroPage