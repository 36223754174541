import React, { useState } from "react";
import { useInView } from "react-intersection-observer";


import bwPaintImage from '../Images/indurstries-page/coating-thickness-monotone.png'
import bwBatteryImage from '../Images/indurstries-page/unsaturated-car-shell.png'
import semiconductorImage from '../Images/indurstries-page/semi-conductor.png'
import bwVegetableImage from '../Images/indurstries-page/unsaturated-vegetables.png'
import nutsImage from '../Images/indurstries-page/peanut-image.jpg'
import mircoscopeImage from '../Images/indurstries-page/mircro-scope.jpg'
import airportImage from '../Images/indurstries-page/unsaturated-airport.png'
import '../SCSS/IndustriesPage.scss'

const IndustriesPage = () => {

    const [currentCarousel, setCurrentCarousel] = useState('manufacturing')

    const { ref:refTitle, inView:inViewTitle } = useInView({
        triggerOnce:true
    });

    const { ref:refCar, inView:inViewCar } = useInView({
        triggerOnce:true
    });
    const { ref:refBattery, inView:inViewBattery } = useInView({
        triggerOnce:true
    });
    const { ref:refSemi, inView:inViewSemi } = useInView({
        triggerOnce:true
    });
    const { ref:refFruits, inView:inViewFruits } = useInView({
        triggerOnce:true
    });
    const { ref:refNuts, inView:inViewNuts } = useInView({
        triggerOnce:true
    });
    const { ref:refAirport, inView:inViewAirport } = useInView({
        triggerOnce:true
    });
    const { ref:refDrugs, inView:inViewDrugs } = useInView({
        triggerOnce:true
    });



    return (
        <div className="main industries-page">
            <section className={inViewTitle? "carousel-section animate": "carousel-section"} ref={refTitle}>
                <h1>Industries we can contribute to </h1>
                <p className="description">Find out which industries ANOR can contribute to so that you can understand the applications of our technology and how it can benefit your own overall business. Explore the carousel below to view the different industries we can play a part in</p>


                <div className="carousel">
                    <h3 className="instruction"> Click on each box to find out more!</h3>
                    <input type="radio" name="slider" id="item-1"  onClick={() => setCurrentCarousel('food-safety')}/>
                    <input type="radio" name="slider" id="item-2" defaultChecked={true} onClick={() => setCurrentCarousel('manufacturing')}/>
                    <input type="radio" name="slider" id="item-3" onClick={() => setCurrentCarousel('security')}/>
                    <div class="cards">
                        <label class="card" for="item-1" id="song-1">
                            <div className="card-title">
                                Food Safety
                            </div>
                        </label>
                        <label class="card" for="item-2" id="song-2">
                            <div className="card-title">
                                Manufacturing
                            </div>
                        </label>
                        <label class="card" for="item-3" id="song-3">
                            <div className="card-title">
                                Security
                            </div>
                        </label>
                    </div>
                </div>
            </section>
            <section className={currentCarousel == 'manufacturing'?("carousel-blocks"):("carousel-blocks inactive")}>
                <div className={inViewCar?'block animate':'block'} ref={refCar}>
                    <div className="content-b">
                        <h2>
                            <span>Protective</span> Coating Measurement
                        </h2>
                        <p>
                            Senstrio uses spectroscopy to measure coating thickness accurately. By analyzing the interaction of light with the coated surface, Senstrio extracts information about the coating layer's thickness. This non-destructive and non-contact method offers a fast and reliable solution for quality control and process optimization. It is versatile and applicable to a wide range of vehicles, including automobiles, aviation vehicles, and more.
                        </p>
                    </div>
                    <div className="mask-container mask-animate">
                        <div className="color-image"></div>
                        <img src={bwPaintImage} alt="" />
                    </div>
                </div>
                <div className={inViewBattery?"block reverse-block animate":"block reverse-block"} ref={refBattery}>
                    <div className="content-o">
                        <h2>
                            <span>Lithium Battery</span> Coating Measurement                        
                        </h2>
                        <p>
                            Spectroscopy enables precise measurement of the coating thickness on electric vehicle (EV) batteries. With Senstrio's non-invasive analysis, the protective coatings can be accurately assessed, ensuring optimal battery performance and longevity. By analyzing light interaction, Senstrio provides valuable insights into coating thickness and uniformity, enhancing battery quality and safety                        
                        </p>
                    </div>
                    <div className="mask-container-2 mask-animate">
                        <div className="color-image"></div>
                        <img src={bwBatteryImage} alt="" />
                    </div>
                </div>
                <div className={inViewSemi?"block animate":"block"} ref={refSemi}>
                    <div className="content-b">
                        <h2>
                            <span>Semiconductor</span> Coating Measurement                        
                        </h2>
                        <p>
                            Senstrio provides accurate and non-destructive coating thickness measurement for semiconductor chips. Using spectroscopic analysis, Senstrio determines the thickness of the coating layer, enabling precise quality control and optimizing chip performance.
                        </p>
                    </div>
                    <div className="mask-container-3 mask-animate">
                        <img src={semiconductorImage} alt="" />
                    </div>
                </div>
            </section>
            <section className={currentCarousel == 'food-safety'?("carousel-blocks"):("carousel-blocks inactive")}>
                <div className={inViewFruits?"block animate":"block"} ref={refFruits}>
                    <div className="content-b">
                        <h2>
                        Vegetable <span>Freshness</span> Sorting
                        </h2>
                        <p>
                            Senstrio THz detects vegetable freshness by utilizing Terahertz technology. It assesses key indicators like water content and cell structure non-invasively, providing insights into freshness levels. This enables informed decisions on storage, transportation, and shelf life, minimizing waste and maximizing quality.
                        </p>
                    </div>
                    <div className="mask-container-4 mask-animate">
                        <div className="color-image"></div>
                        <img src={bwVegetableImage} alt="" />
                    </div>
                </div>
                <div className={inViewNuts?"block reverse-block animate":"block reverse-block"} ref={refNuts}>
                    <div className="content-o">
                        <h2>
                            <span>Nut Type and Quality</span> Sorting                       
                        </h2>
                        <p>
                        Senstrio THz revolutionizes nut sorting with its Terahertz technology. By analyzing unique signatures, it enables fast and precise sorting based on size, density, moisture, and defects. Enhancing productivity and quality control, Senstrio THz ensures efficient and accurate nut sorting for optimal yield.
                        </p>
                    </div>
                    <div className="mask-container-5 mask-animate">
                        <img src={nutsImage} alt="" />
                    </div>
                </div>
            </section>
            <section className={currentCarousel == 'security'?("carousel-blocks"):("carousel-blocks inactive")}>
                <div className={inViewAirport?"block animate":"block"} ref={refAirport}>
                    <div className="content-b">
                            <h2>
                                Airport Sercurity                       
                            </h2>
                            <p>
                                Senstrio can play a crucial role in enhancing airport security by assisting in the scanning and screening of baggage. Leveraging its advanced technology, Senstrio offers efficient and accurate detection of potential threats or prohibited items within luggage. With its non-invasive and non-destructive approach, Senstrio enables airport terminals to streamline security procedures, ensuring the safety of passengers and maintaining a secure environment within a fast-paced airport setting
                            </p>
                        </div>
                        <div className="mask-container-6 mask-animate">
                            <div className="color-image"></div>
                            <img src={airportImage} alt="" />
                        </div>
                    </div>
                <div className={inViewDrugs?"block reverse-block animate":"block reverse-block"} ref={refDrugs}>
                  <div className="content-o">
                        <h2>
                            Illicit and Harmful Chemical Detection
                        </h2>
                        <p>
                            Senstrio's use of Terahertz (THz) fingerprint data enables precise detection of illicit and harmful chemicals. This non-invasive method enhances safety in areas like border control and law enforcement. By analyzing unique THz signatures, Senstrio empowers authorities to identify and prevent the circulation of dangerous substances, ensuring public well-being and maintaining a secure environment.                        </p>
                    </div>
                    <div className="mask-container-7 mask-animate">
                        <img src={mircoscopeImage} alt="" />
                    </div>
                </div>
            </section>
            <div className="bg">
                <div class="z-3">
                    <div class="tile top-left animate-opacity freq-5"></div>
                    <div class="tile top-right animate-opacity freq-9"></div>
                    <div class="tile bottom-left animate-opacity freq-7"></div>
                    <div class="tile bottom-right animate-opacity freq-10"></div>
                </div>
                <div class="z-2">
                    <div class="tile top-left animate-opacity freq-9 delay-2"></div>
                    <div class="tile top-right animate-opacity freq-5 delay-2"></div>
                    <div class="tile bottom-left animate-opacity freq-6 delay-4"></div>
                    <div class="tile bottom-right animate-opacity freq-10 delay-4"></div>
                </div>
                <div class="z-1">
                    <div class="tile top-left animate-opacity freq-7 delay-2"></div>
                    <div class="tile top-right animate-opacity freq-5 delay-4"></div>
                    <div class="tile bottom-left animate-opacity freq-9 delay-2"></div>
                    <div class="tile bottom-right animate-opacity freq-5 delay"></div>
                </div>
            </div>
        </div>
    )
}

export default IndustriesPage