import React, { useState } from "react";
import { NavLink, useLocation, useNavigate  } from 'react-router-dom';
import AnorLogoTop from '../Images/anor-technologies-logo-top-half.png'
import AnorLogoBottom from '../Images/anor-technologies-logo-bottom-half.png'
import '../SCSS/NavigationBar.scss'

const NavigationBar = () =>  {

    const [activeNavBar, setActiveNavBar] = useState(false)

    const location = useLocation()
    const navigate = useNavigate()

    return (
        <div className={ ['/', '/productsandservices', '/aboutus', '/industries'].includes(location.pathname)?("navigation-bar"):("navigation-bar no-background")}>
            <nav className="navigator">

                <div className="company-logo" onClick={() => navigate('/')}>
                    <img src={AnorLogoTop} alt="" className="company-logo-top" />
                    <img src={AnorLogoBottom} alt="" className="company-logo-bottom" />
                </div>
                <div className="link-wrapper">
                    <ul className={activeNavBar?"navigation-list active":"navigation-list"}>
                        <div className="close-button" onClick={() => setActiveNavBar(false)}>
                            <i className='bx bx-x'></i>
                        </div>
                        <li className="navigation-item nav-one">
                            <NavLink className="navigation-link " onClick={() => setActiveNavBar(false)} to='/'>
                                Home
                            </NavLink>
                        </li>
                        <li className="navigation-item nav-two">
                            <NavLink className="navigation-link "  onClick={() => setActiveNavBar(false)} to='/productsandservices'>
                                Products
                            </NavLink>
                        </li>
                        <li className="navigation-item nav-three">
                            <NavLink className="navigation-link" onClick={() => setActiveNavBar(false)} to='/industries'>
                                Industries
                            </NavLink>
                        </li>
                        <li className="navigation-item nav-four">
                            <NavLink className="navigation-link" onClick={() => setActiveNavBar(false)} to='/aboutus'>
                                About
                            </NavLink>
                        </li>
                        <li className="navigation-item nav-five">
                            <NavLink className="navigation-link"  onClick={() => setActiveNavBar(false)} to='/contactus'>
                                Contact Us
                            </NavLink>
                        </li>
                    </ul>
                    <button className="senstrio-button">
                        Login to Senstrio
                    </button>
                    <div className="hamburger-menu" onClick={() => setActiveNavBar(true)}>
                        <div className="rect1 rect"></div>
                        <div className="rect2 rect"></div>
                        <div className="rect3 rect"></div>
                    </div>
                </div>
            </nav>
        </div>
    )
}

export default NavigationBar