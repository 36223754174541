import React from "react";
import { useInView } from "react-intersection-observer";

import '../SCSS/ContactUsPage.scss'


const ContactUsPage = () => {
    
    const { ref:refForm, inView:inViewForm } = useInView({
        triggerOnce:true
    });

    return(
        <div className="main contact-us-page">
            <section className="title-section">
                <h4>CONTACT US</h4>
                <h1 className="top-h1">How can you <span className="span-o">reach</span> us?<br /> </h1>
                <h1 className="bottom-h1">You can find our company in <span className="span-b">these areas</span></h1>
            </section>
            <section className="map-section">
                <div className="map-container">
                    <div className="information">
                        <h1><span className="span-b">Singapore</span></h1>
                        <p>Main Startup Office</p>
                        <h2>Location</h2>
                        <p>Singapore Launch Pad <br />75 Ayer Rajah Crescent, Singapore 139953</p>
                    </div>
                    <div className="map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7955748127106!2d103.78423237461477!3d1.297340998690338!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da0f8e8c000001%3A0x25ea431f3c831aa6!2sANOR%20TECHNOLOGIES%20PTE%20LTD!5e0!3m2!1sen!2ssg!4v1687683039123!5m2!1sen!2ssg"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
            </section>
            <section className={inViewForm?"form-section animate-form":"form-section"} ref={refForm}>
                <div className="form-section-container">
                    <div className="form-information">
                        <h1>Drop us a Message</h1>
                        <p>To contact us drop us an email using this form to contact@anor-tech.com or call +65 6592 9740 and we will get back to you as soon as possible</p>
                        <div className="social-box">
                            <a href="https://www.linkedin.com/company/anor-tech/?originalSubdomain=sg"  target="_blank"><i className='bx bxl-linkedin-square' id='icon-1'></i></a>
                            <i className='bx bxl-instagram-alt' id='icon-2' ></i>
                            <a href="https://www.youtube.com/@anortechnologies5002" target="_blank"><i className='bx bxl-youtube' id='icon-3'></i></a>
                            <i className='bx bxl-twitter' id='icon-4' ></i>
                            <i className='bx bxl-facebook' id='icon-5'></i>
                            <div className="dash"></div>
                            <div className="follow-us">Follow Us</div>
                        </div>
                    </div>
                    <form action="https://formsubmit.co/contact@anor-tech.com" method="POST" className="form">
                        <div className="form-entry">
                            <label>First Name</label> 
                            <input type="text" name='first Name' placeholder="Enter your first name" />
                        </div>
                        <div className="form-entry">
                            <label>Last Name</label> 
                            <input type="text" name="last Name" placeholder="Enter your last name" />
                        </div>
                        <div className="form-entry">
                            <label>Email</label> 
                            <input type="email" name='email' placeholder="Enter your email" />
                        </div>
                        <div className="form-entry">
                            <label>Company</label> 
                            <input type="text" name='company name' placeholder="Enter your company name" />
                        </div>
                        <div className="form-entry">
                            <label>Industry</label> 
                            <input type="text" name='industry' placeholder="Enter your industry" />
                        </div>
                        <div className="form-entry">
                            <label>Message</label> 
                            <textarea name="main message"  cols="30" rows="10" placeholder="Comments"></textarea>
                        </div>
                        <button>Submit</button>
                    </form>
                </div>
            </section>
        </div>
    )
}

export default ContactUsPage